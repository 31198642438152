
.abonos-bucaramanga-2025-1 {
    #header-section {
        background-color: rgb(47, 47, 47);
        width: 100%;
    }
    
    .header-container {
        padding: 10px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        }
    }

    #content-section {
        background-color: rgb(47, 47, 47);
        padding: 11px 0px 120px 0px;
    }

    .content-container {
        &__left-content {
            padding: 0px 15px 15px 15px;
            .title-cedula {
                color: #f6d509;
                font-family: "Secular One", Sans-serif;
                font-size: 37px;
                font-weight: 800;
                text-transform: uppercase;
                line-height: 36px;
                letter-spacing: 2.1px;
                text-shadow: -1px 7px 0px rgba(0, 0, 0, 0.3);
                margin-block: 20px;
                text-align: center;
            }
            .description-cedula {
                color: #f6d509;
                font-family: "Roboto", Sans-serif;
                font-size: 20px;
                font-weight: 600;
                line-height: 1;
                margin-bottom: 20px;
                text-align: center;
            }
            .content-cedula {
                color: #ffffff;
                font-family: "Roboto", Sans-serif;
                font-size: 20px;
                font-weight: 500;
                line-height: 1.2;
            }

            img {
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
                margin-bottom: 40px;
            }
            &__btn-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .btn-buy-ticket {
                    font-family: "Roboto", Sans-serif !important;
                    fill: #ffffff !important;
                    color: #ffffff !important;
                    background-color: #16c809 !important;
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5) !important;
                    font-weight: 500 !important;
                    margin-bottom: 20px !important;
                    border: none !important;
                    padding: 12px 24px !important;
                    font-size: 16px !important;
                    border-radius: 4px !important;
                    line-height: 1;
                    svg {
                        margin-right: 17px;
                    }
                    &:hover {
                        background-color: #0d6d0a !important;
                    }
                }
                .btn-buy-information {
                    font-family: "Roboto", Sans-serif !important;
                    font-weight: 500 !important;
                    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3) !important;
                    fill: #ffffff !important;
                    color: #ffffff !important;
                    background-color: #25D366 !important;
                    margin-bottom: 20px !important;
                    border: 1px solid white !important;
                    padding: 12px 24px !important;
                    font-size: 16px !important;
                    border-radius: 4px !important;
                    line-height: 1;
                    svg {
                        margin-right: 17px;
                        height: 20px;
                        width: 20px;
                    }
                    &:hover {
                        background-color: #128c4f !important;
                    }
                }
                .btn-buy-download {
                    font-family: "Roboto", Sans-serif !important;
                    font-weight: 500 !important;
                    fill: #054b14 !important;
                    color: #054b14;
                    background-color: #f2e306;
                    line-height: 1;
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5) !important;
                    border: none !important;
                    padding: 12px 24px !important;
                    font-size: 16px !important;
                    border-radius: 4px !important;
                    &:hover {
                        background-color: #aa9f07 !important;
                    }
                }
            }
        }

        &__right-content {
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
            padding: 15px 15px 0px 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .alert-info {
                color: #31708f;
            }
            &__title {
                text-align: center;
                width: 100%;
                color: #ffffff;
                font-family: "Secular One", Sans-serif;
                font-size: 37px;
                font-weight: 800;
                text-transform: uppercase;
                text-decoration: underline;
                line-height: 36px;
                letter-spacing: 2.1px;
                margin-bottom: 20px;
                text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.3);
            }

            &__header-image,
            &__content-image {
                margin-bottom: 20px;
            }

            &__footer-image {
                min-width: calc(100% + 30px);
            }

            .btn-buy-ticket {
                font-family: "Roboto", Sans-serif !important;
                fill: #ffffff !important;
                color: #ffffff !important;
                background-color: #b06e0e !important;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5) !important;
                font-weight: 500 !important;
                margin-bottom: 20px !important;
                border: none !important;
                padding: 12px 24px !important;
                font-size: 16px !important;
                border-radius: 4px !important;
                line-height: 1;
            }

            .content-text {
                h2 {
                    padding: 0px 0px 20px 0px;
                    color: #ffffff;
                    font-family: "Roboto Condensed", Sans-serif;
                    font-size: 17px;
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 22px;
                    letter-spacing: 2px;
                }

                .second-title {
                    strong {
                        font-size: 16px;
                    }
                }

                p {
                    text-align: start;
                    color: #f9d904;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.2em;
                    margin-bottom: calc(1rem);
                }

                .final-message {
                    text-align: start;
                    padding: 0px;
                    color: #f9d904;
                }

                ul,
                ol {
                    padding-left: 2.5rem;
                    li {
                        color: #f9d904;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1.2em;
                        font-family: "Roboto", Sans-serif;
                        strong {
                            font-size: 16px;
                            color: white;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    .final-bucaramanga {
        .header-container,
        .content-container {
            max-width: 1140px !important;
        }
    }
}
