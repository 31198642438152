.aliados {
    background: var(--primary-color);

    .header-aliados {
        position: relative;
        .header-title-aliados {
            width: 100%;
            position: absolute;
            top: 0;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            h1 {
                color: white;
                font-weight: 600;
                text-align: center;
                padding: 0;
                margin: 0;

                font-size: 80px;
                text-transform: uppercase;
                line-height: 104px;
                letter-spacing: 2px;
            }
        }
    }
    .contact-container {
        padding: 10px;
        &__left-content {
            padding: 20px;
            &__title {
                color: #fff;
                font-weight: 600;
                text-align: center;
                line-height: 1.2;
                font-size: 32px;
                margin-bottom: 20px;
                span {
                    font-family: "Roboto Slab", Sans-serif;
                    color: #fff;
                    font-weight: 600;
                    text-align: center;
                    line-height: 1.2;
                    font-size: 32px;
                }
            }

            &__content {
                color: #fff;
                font-weight: 400;
                text-align: start;
                line-height: 1.4;
                font-size: 16px;
            }
        }

        &__right-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;

            img {
                width: 38%;
                height: auto;
                margin-bottom: 20px;
            }
            .btn-contact {
                background-color: #011563 !important;
                border-style: solid !important;
                font-weight: 500 !important;
                line-height: 1 !important;
                font-size: 15px !important;
                padding: 12px 24px !important;
                border-radius: 3px !important;
                color: #fff !important;
                fill: #fff !important;
                text-align: center !important;
                border-color: white !important;
            }
        }
    }
    .partners-container {
        padding: 60px 0px 120px 0px;
        &__partner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            .btn-go-partner {
                background-color: #011563 !important;
                border-style: solid !important;
                font-weight: 500 !important;
                line-height: 1 !important;
                font-size: 15px !important;
                padding: 12px 24px !important;
                border-radius: 3px !important;
                color: #fff !important;
                fill: #fff !important;
                text-align: center !important;
                border-color: white !important;
                margin-top: 20px;
            }

            .img-large {
                height: auto;
            }

            &__title {
                font-weight: 600;
                color: white;
                font-size: 28px;
            }

            &__content {
                font-weight: 400;
                color: white;
                margin: 0;
                font-size: 16px;
            }
        }
    }
    .aliados-footer {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        background: #343a40;
        background-size: auto;
        border-top-width: 1px;
        border-top-style: solid;
        .footer-container {
            &__left-content {
                text-align: start;
                a {
                    color: #f8f9fa;
                    text-decoration: none;
                    font-family: Bebas Neue;
                    font-size: 16px;
                    line-height: 1.5;
                }
            }
            &__right-content {
                text-align: end;
                p {
                    color: #f8f9fa;
                    margin: 0px;
                    font-family: Bebas Neue;
                    font-size: 16px;
                    line-height: 1.5;
                }
            }
        }
    }
}
