#home-container {
    background: var(--primary-color);
}
.header-container {
    width: 100%;
    &__logo {
        background-image: url("../assets/images/quick-header-banner.jpg");
        background-size: cover;
        background-position: center;
        height: 100px;
        overflow: hidden;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        transition:
            background 0.3s,
            border 0.3s,
            border-radius 0.3s,
            box-shadow 0.3s;
    }

    &__buttons-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;

        .icon-address-card {
            margin-right: 21px;
        }

        .btn-primary {
            background-color: var(--background-btn-primary) !important;
            color: white !important;
            padding: 12px 24px !important;
            font-size: 15px !important;
            border-radius: 3px !important;
            border: none !important;
            cursor: pointer !important;
            font-weight: 500 !important;
            line-height: 1 !important;
            margin-right: 0;
            margin-bottom: 20px;
            &:hover {
                background-color: var(--background-btn-tertiary) !important;
            }
        }

        .btn-secondary {
            background-color: var(--background-btn-secondary) !important;
            color: white !important;
            padding: 12px 24px !important;
            font-size: 15px !important;
            border-radius: 3px !important;
            border: none !important;
            cursor: pointer;
            font-weight: 500 !important;
            line-height: 1 !important;
            margin-left: 0;
            &:hover {
                background-color: var(--background-btn-tertiary) !important;
            }
        }
    }
}

.event-list-container {
    margin: 0;
    padding: 0;
    text-align: center;
    margin-top: 12px;

    &__title-container {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        background-color: var(--primary-color);
        padding: 10px;
    }
    &__title {
        text-decoration: underline;
        color: white;
        font-family: "Bebas Neue", Sans-serif;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.4px;
        -webkit-text-stroke-width: 0px;
        stroke-width: 0px;
        -webkit-text-stroke-color: #000;
        stroke: #000;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    &__event-card {
        padding: 10px;
        margin-bottom: 20px;
        &__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &__title {
                font-size: 18px;
                font-weight: 600;
                margin: 0;
                padding: 0;
                line-height: 1;
                color: white;
            }
            &__date,
            &__location,
            &__city {
                font-size: 16px;
                font-weight: 400;
                margin: 0;
                padding: 0;
                line-height: 1.2;
                color: white;
            }
        }
        &__image-container {
            width: 100%;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                margin-bottom: 4px;
            }
            &__btn-container {
                width: 100%;
                position: absolute;
                bottom: 5px;
                display: flex;
                justify-content: center;
                button {
                    background-color: var(--background-btn-quaternary) !important;
                    color: white !important;
                    padding: 10px 20px !important;
                    font-size: 13px !important;
                    border-radius: 2px !important;
                    border: none !important;
                    cursor: pointer;
                    font-weight: 500 !important;
                    line-height: 1 !important;
                    &:hover {
                        background-color: var(--background-btn-primary) !important;
                    }
                }
            }
        }
    }
}

.partner-list-container {
    margin: 0;
    padding: 0;
    text-align: center;
    margin-top: 44px;

    &__title-container {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        background-color: var(--primary-color);
        padding: 10px;
        margin-bottom: 18px;
    }
    &__title {
        text-decoration: underline;
        color: white;
        font-family: "Bebas Neue", Sans-serif;
        font-weight: 600;
        letter-spacing: 0.4px;
        font-size: 20px;
        -webkit-text-stroke-width: 0px;
        stroke-width: 0px;
        -webkit-text-stroke-color: #000;
        stroke: #000;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        margin: 0;
        padding: 0;
        line-height: 1;
    }
    &__video-container {
        width: 100%;
        height: 70%;
    }
    &__video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.home-footer {
    background-image: linear-gradient(180deg, #001878 0%, #010210 100%);
    background-color: transparent;
    min-height: 500px;

    .footer-container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 500px;
        padding: 10px;
        &__logo {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__payment-methods-container {
            img {
                width: 100%;
                height: auto;
            }
        }
        &__link-list {
            padding:0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            a {
                cursor: pointer;
            }
            li,
            a {
                font-size: 15px;
                font-family: "Bebas Neue", sans-serif;
                letter-spacing: 0.4px;
                font-weight: 400;
                color: white;
                text-decoration: none;
                list-style: none;
            }
            li {
                margin-top: 5px;
            }
        }
        &__social-media {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            color: white;
            width: 100%;
            a {
                text-decoration: none;
                color: white;
            }
            svg {
                font-size: 25px;
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    .event-list-container {
        &__external {
            max-width: 1140px !important;
        }
    }
    .partner-list-container {
        &__external {
            max-width: 1140px !important;
        }
    }
    .footer-container {
        &__external {
            max-width: 1140px !important;
        }
    }
}

@media screen and (min-width: 768px) {
    .home-footer {
        .footer-container {
            &__link-list {
                align-items: start;
            }
        }
    }
    .header-container {
        &__buttons-container {
            display: flex;
            flex-direction: row;
            .btn-primary {
                margin-right: 3rem;
                margin-bottom: 0px;
            }
            .btn-secondary {
                margin-left: 3rem;
            }
        }
    }
}
