.TYC-page {
    width: 100%;
    background-color: white;
    .header-container-tyc{
        width: 100%;
        background-color: #001878;
        padding-top: 100px;
    }
    .container {
        margin: 0 auto;
        max-width: 1140px;
        text-align: justify;
        .row {
            margin: 20px 0;
            font-size: 12pt;
            font-family: "Barlow";
            color: #7a7a7a;
            h2 {
                color: #001878;
                font-family: "Roboto", Sans-serif;
                font-size: 30px;
                font-weight: 300;
                text-transform: uppercase;
                letter-spacing: 2px;
                padding: 50px 0px 0px 0px;
                text-align: center;
                svg {
                    font-size: 30px;
                    margin-right: 10px;
                }
                strong {
                    color: #001878;
                    font-family: "Roboto", Sans-serif;
                    font-size: 30px;
                    font-weight: 400;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                }
            }
            h3 {
                color: #7a7a7a;
                font-size: 12pt;
                font-family: "Barlow";
                font-weight: bolder;
                text-transform: uppercase;
                text-align: center;
                svg {
                    font-size: 30px;
                    margin-right: 10px;
                }
                strong {
                    color: #7a7a7a;
                    font-family: "Roboto", Sans-serif;
                    font-size: 30px;
                    font-weight: 500;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                }
            }
            ol {
                padding-left: 20px;
                color: #7a7a7a;
                li {
                    font-size: 12pt;
                    font-family: "Barlow";
                    h6 {
                        font-size: 1.2rem;
                        margin-bottom: 10px;
                    }

                    ul {
                        list-style-type: disc;
                        margin: 10px 0;
                        padding-left: 20px;

                        li {
                            font-size: 1rem;
                            line-height: 1.5;
                            margin: 5px 0;
                        }
                    }
                    ol {
                        margin: 20px 0;
                        padding-left: 20px;

                        li {
                            font-size: 1rem;
                            line-height: 1.5;
                            margin: 5px 0;
                        }
                    }
                }
            }
        }
    }
    .aliados-footer {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        background: #343a40;
        background-size: auto;
        border-top-width: 1px;
        border-top-style: solid;
        .footer-container {
            &__left-content {
                text-align: start;
                a {
                    color: #f8f9fa;
                    text-decoration: none;
                    font-family: Bebas Neue;
                    font-size: 16px;
                    line-height: 1.5;
                }
            }
            &__right-content {
                text-align: end;
                p {
                    color: #f8f9fa;
                    margin: 0px;
                    font-family: Bebas Neue;
                    font-size: 16px;
                    line-height: 1.5;
                }
            }
        }
    }
}
