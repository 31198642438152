:root {
    --primary-color: #031e6b;
    --background-btn-primary: #1ca22e;
    --background-btn-secondary: #e79123;
    --background-btn-tertiary: #61ce70;
    --background-btn-quaternary: #06cb48;
}

* {
    box-sizing: border-box;
    -webkit-appearance: none !important;
    font-size: 14px;
}

body {
    font-family: "Roboto", sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}
