.back-to-the-mt {
    #header-section {
        background-image: linear-gradient(270deg, #141848 54%, #81288b 100%);
        background-color: transparent;
        width: 100%;
    }
    .header-container {
        padding: 10px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            margin-bottom: 20px;
        }
    }

    #content-section {
        background-color: transparent;
        background-image: linear-gradient(270deg, #141848 54%, #81288b 100%);
        padding: 11px 0px 120px 0px;
    }

    .content-container {
        &__left-content {
            padding: 0px 15px 15px 15px;
            img {
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
                margin-bottom: 40px;
            }
            &__btn-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .btn-buy-ticket {
                    font-family: "Roboto", Sans-serif !important;
                    fill: #ffffff !important;
                    color: #ffffff !important;
                    background-color: #81288b !important;
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5) !important;
                    font-weight: 500 !important;
                    margin-bottom: 20px !important;
                    border: none !important;
                    padding: 15px 30px !important;
                    font-size: 16px !important;
                    border-radius: 4px !important;
                    line-height: 1;
                    svg {
                        margin-right: 17px;
                    }
                    &:hover {
                        background-color: var(--background-btn-primary) !important;
                    }
                }
                .btn-buy-information {
                    font-family: "Roboto", Sans-serif !important;
                    font-weight: 500 !important;
                    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3) !important;
                    fill: #ffffff !important;
                    color: #ffffff !important;
                    background-color: transparent !important;
                    background-image: linear-gradient(150deg, #054eaf 15%, #81288b 100%) !important;
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5) !important;
                    margin-bottom: 20px !important;
                    border: none !important;
                    padding: 15px 30px !important;
                    font-size: 16px !important;
                    border-radius: 4px !important;
                    line-height: 1;
                    svg {
                        margin-right: 17px;
                    }
                }
                .btn-buy-download {
                    font-family: "Roboto", Sans-serif !important;
                    font-weight: 500 !important;
                    fill: #054b14 !important;
                    color: #054b14 !important;
                    line-height: 1;
                    background-color: #f2e306 !important;
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5) !important;
                    border: none !important;
                    padding: 15px 30px !important;
                    font-size: 16px !important;
                    border-radius: 4px !important;
                    &:hover {
                        background-color: #AA9F07 !important;
                    }
                }
            }
        }

        &__right-content {
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
            padding: 15px 15px 15px 15px;
            &__title {
                text-align: center;
                width: 100%;
                color: #ffffff;
                font-family: "Secular One", Sans-serif;
                font-size: 37px;
                font-weight: 800;
                text-transform: uppercase;
                text-decoration: underline;
                line-height: 36px;
                letter-spacing: 2.1px;
                margin-bottom: 20px;
            }

            &__header-image,
            &__content-image {
                margin-bottom: 20px;
            }

            .content-text {
                h2 {
                    padding: 0px 0px 20px 0px;
                    color: #ffffff;
                    font-family: "Roboto Condensed", Sans-serif;
                    font-size: 17px;
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 22px;
                    letter-spacing: 2px;
                }

                p,
                .final-message {
                    text-align: justify;
                    color: #ffffff;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1.4em;
                    margin-bottom: calc(1rem + 20px);
                }

                .final-message {
                    font-size: 16px;
                }

                ol {
                    padding-left: 2.5rem;
                    li {
                        color: #ffffff;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1.2em;
                        font-family: sans-serif;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    .back-to-the-mt {
        .header-container,
        .content-container {
            max-width: 1140px !important;
        }
    }
}
